import { useTranslation } from "react-i18next";

import marketUp from "../assets/market-up.svg";
import target from "../assets/target.svg";
import flag from "../assets/flag.svg";
import { SectionHeader } from "../components/SectionHeader";

export function MarketOverview() {
  const { t } = useTranslation();

  return (
    <section className="text-black bg-white">
      <div className="mx-auto py-16 md:py-32 md:max-w-7xl">
        <SectionHeader title={t("marketOverview.title")} />
        <div className="grid gap-5 md:grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 md:items-top py-10 md:py-20 mx-10">
          <div>
            <img src={marketUp} alt="up" className="mb-4" />
            <h2 className="text-base uppercase font-bold mb-4">
              {t("marketOverview.industryAnalysis.title")}
            </h2>
            <p>{t("marketOverview.industryAnalysis.text")}</p>
          </div>
          <div>
          <img src={target} alt="target" className="mb-4" />
            <h2 className="text-base uppercase font-bold mb-4">
              {t("marketOverview.targetMarket.title")}
            </h2>
            <p>{t("marketOverview.targetMarket.text")}</p>
          </div>
          <div>
          <img src={flag} alt="flag" className="mb-4" />
            <h2 className="text-base uppercase font-bold mb-4">
              {t("marketOverview.competitiveLandscape.title")}
            </h2>
            <p>{t("marketOverview.competitiveLandscape.text")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
