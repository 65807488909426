import { useTranslation } from "react-i18next";

import logo from "../assets/logo.svg";
import rightArrow from "../assets/right-arrow.svg";
import kyivDynamics from "../assets/kyivdynamics.svg";
import LanguageSelector from "../components/LanguageSelector";
import { SectionHeader } from "../components/SectionHeader";

export function ExecutiveSummary() {
  const { t } = useTranslation();

  return (
    <section className="text-white bg-black relative">
      <div className="absolute top-0 left-0 w-full h-full bg-[url('assets/bg1-lq.jpg')] bg-cover bg-center opacity-20  mix-blend-luminosity"></div>
      <div className="relative mx-auto">
        <header className="sm:flex justify-between items-center px-4 py-4 md:max-w-7xl mx-auto">
          <a href="/" className="flex group items-center my-4">
            <img src={logo} alt="logo" className="px-2" />
            <img src={kyivDynamics} alt="Kyiv Dynamics" className="px-2" />
          </a>
          <div className="flex justify-between items-center flex-cols gap-2">
            <LanguageSelector />
            <a
              href="#contact"
              className="hover:bg-primary-400 text-black group flex items-center bg-primary-500 text-sm font-medium pl-2 pr-3 py-2 shadow-sm"
            >
              {t("getInTouch")}
              <img src={rightArrow} alt="rightArrow" className="ml-2" />
            </a>
          </div>
        </header>
        <div className="text-white flex flex-col items-start gap-10 md:gap-20 py-10 md:py-32 px-10 md:max-w-5xl mx-auto">
          <SectionHeader title={t("executiveSummary.title")} />
          <div className="inline-flex flex-col items-center gap-10 md:gap-20">
            <div>
              <h2 className="text-base uppercase font-bold mb-4">
                {t("executiveSummary.intro.title")}
              </h2>
              <p className="opacity-60">{t("executiveSummary.intro.text")}</p>
            </div>
            <div>
              <h2 className="text-base uppercase font-bold mb-4">
                {t("executiveSummary.proposal.title")}
              </h2>
              <p className="opacity-60">
                {t("executiveSummary.proposal.text")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
