import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = (e: any) => {
    e.preventDefault();
    i18n.changeLanguage(i18n.language === "en" ? "uk" : "en");
  };

  return (
    <a
      href={`?lng=${i18n.language === "en" ? "uk" : "en"}`}
      onClick={toggleLanguage}
      className="hover:bg-white-400 text-black group flex items-center bg-white text-sm font-medium pl-2 pr-3 py-2 shadow-sm cursor-pointer uppercase opacity-60"
    >
      {i18n.language === "en" ? "ua" : "en"}
    </a>
  );
};

export default LanguageSelector;
