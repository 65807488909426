import { useTranslation } from "react-i18next";

import { SectionHeader } from "../components/SectionHeader";

export function FinancialProjections() {
  const { t } = useTranslation();

  return (
    <section className="text-white bg-black relative">
      <div className="absolute top-0 left-0 w-full h-full bg-[url('assets/bg3-lq.jpg')] bg-cover bg-center opacity-20  mix-blend-luminosity"></div>
      <div className="mx-auto py-16 md:py-32 relative px-10 md:max-w-5xl">
        <div className="text-white inline-flex flex-col items-start gap-10 md:gap-20 items-center">
          <SectionHeader title={t("financialProjections.title")} />
          <p className="opacity-60">{t("financialProjections.text")}</p>
        </div>
      </div>
    </section>
  );
}
