import { ChangeEvent, FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import { Modal } from "../components/Modal";

const FUNCTION_ENDPOINT =
  "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-579be14f-963f-4c13-98a5-20c6d284afef/default/kyiv-dynamics-contact-us";

interface FormData {
  email: string;
  phone: string;
  message: string;
}

interface ModalState {
  isVisible: boolean;
  message: string;
}

export function Conclusion() {
  const { t } = useTranslation();

  const [formData, setFormData] = useState<FormData>({
    email: "",
    phone: "",
    message: "",
  });

  const [modalState, setModalState] = useState<ModalState>({
    isVisible: false,
    message: "",
  });

  const closeModal = () => setModalState({ ...modalState, isVisible: false });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const response = await fetch(FUNCTION_ENDPOINT, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setFormData({ email: "", phone: "", message: "" });
        setModalState({
          isVisible: true,
          message: t("contact.success"),
        });
      } else {
        setModalState({ isVisible: true, message: t("contact.failed") });
      }
    } catch (error) {
      setModalState({
        isVisible: true,
        message: t("contact.error"),
      });
    }
  };

  return (
    <section className="text-white bg-black relative" id="contact">
      <div className="absolute top-0 left-0 w-full h-full bg-[url('assets/bg4-lq.jpg')] bg-cover bg-center opacity-20 mix-blend-luminosity"></div>
      <footer className="relative p-16 max-w-5xl mx-auto">
        <div className="grid md:grid-rows-2 lg:grid-cols-2 lg:grid-rows-1 gap-10">
          <div>
            <h1 className="text-4xl font-bold uppercase tracking-widest mb-16">
              {t("conclusion.title")}
            </h1>
            <p className="opacity-60">{t("conclusion.text")}</p>
          </div>
          <form
            className="flex flex-col items-start items-center w-64 md:w-96"
            onSubmit={handleSubmit}
          >
            <div className="mb-6 w-full">
              <div className="text-grey">{t("contact.email")}</div>
              <input
                name="email"
                className="input w-full p-2"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6 w-full">
              <div className="text-gray">{t("contact.phone")}</div>
              <input
                name="phone"
                className="input w-full p-2"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="mb-12 w-full">
              <div className="text-gray">{t("contact.message")}</div>
              <textarea
                name="message"
                className="input w-full p-2"
                value={formData.message}
                onChange={handleChange}
              />
            </div>
            <div className="mb-6 w-full">
              <button className="hover:bg-primary-400 text-black bg-primary-500 text-sm font-medium pl-2 pr-3 py-2 shadow-sm w-full">
                {t("contact.submit")}
              </button>
            </div>
          </form>
          <Modal
            isVisible={modalState.isVisible}
            message={modalState.message}
            onClose={closeModal}
          />
        </div>
      </footer>
    </section>
  );
}
