import { useTranslation } from "react-i18next";
import { SectionHeader } from "../components/SectionHeader";

export function Solution() {
  const { t } = useTranslation();

  return (
    <section className="text-white bg-black relative">
      <div className="absolute top-0 left-0 w-full h-full bg-[url('assets/bg2-lq.jpg')] bg-cover bg-center opacity-20 mix-blend-luminosity"></div>
      <div className="mx-auto py-16 md:py-32 relative px-10 md:max-w-5xl">
        <div className="text-white inline-flex flex-col items-start gap-10 md:gap-20 items-center">
          <SectionHeader title={t("solution.title")} />
          <div className="inline-flex flex-col items-start gap-10 md:gap-20">
            <div>
              <h2 className="text-base uppercase font-bold mb-4">
                {t("solution.problemStatement.title")}
              </h2>
              <p className="opacity-60">
                {t("solution.problemStatement.text")}
              </p>
            </div>
            <div>
              <h2 className="text-base uppercase font-bold mb-4">
                {t("solution.offering.title")}
              </h2>
              <p className="opacity-60 mb-4">{t("solution.offering.text")}</p>
              <p className="opacity-60 mb-4">
                {t("solution.offering.listTitle")}
              </p>
              <ul className="list-disc list-inside opacity-60">
                <li>{t("solution.offering.listItem1")}</li>
                <li>{t("solution.offering.listItem2")}</li>
                <li>{t("solution.offering.listItem3")}</li>
                <li>{t("solution.offering.listItem4")}</li>
              </ul>
            </div>
            <div>
              <h2 className="text-base uppercase font-bold mb-4">
                {t("solution.technologicalInnovations.title")}
              </h2>
              <p className="opacity-60 mb-4">
                {t("solution.technologicalInnovations.text")}
              </p>
              <ul className="list-disc list-inside opacity-60">
                <li>{t("solution.technologicalInnovations.item1")}</li>
                <li>{t("solution.technologicalInnovations.item2")}</li>
                <li>{t("solution.technologicalInnovations.item3")}</li>
                <li>{t("solution.technologicalInnovations.item4")}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
