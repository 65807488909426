import { ExecutiveSummary } from "./sections/ExecutiveSummary";
import { MarketOverview } from "./sections/MarketOverview";
import { Solution } from "./sections/Solution";
import { GrowthPlan } from "./sections/GrowthPlan";
import { FinancialProjections } from "./sections/FinancialProjections";
import { InvestmentNeeds } from "./sections/InvestmentNeeds";
import { Conclusion } from "./sections/Conclusion";

function App() {
  return (
    <>
      <ExecutiveSummary />
      <MarketOverview />
      <Solution />
      <GrowthPlan />
      <FinancialProjections />
      <InvestmentNeeds />
      <Conclusion />
    </>
  );
}

export default App;
