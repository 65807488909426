import { useTranslation } from "react-i18next";

interface ModalProps {
  isVisible: boolean;
  message: string;
  onClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  isVisible,
  message,
  onClose,
}) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  const handleBackdropClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center"
      onClick={handleBackdropClick}
    >
      <div className="bg-white p-6 shadow-xl">
        <p className="text-gray-800 text-lg">{message}</p>
        <button
          onClick={onClose}
          className="mt-4 bg-primary-500 hover:bg-primary-400 text-black font-bold py-2 px-4"
        >
          {t("modal.close")}
        </button>
      </div>
    </div>
  );
};
