import { useTranslation } from "react-i18next";
import { SectionHeader } from "../components/SectionHeader";

export function InvestmentNeeds() {
  const { t } = useTranslation();

  return (
    <section className="text-black bg-white">
      <div className="mx-auto py-16 md:py-32 px-10 md:max-w-5xl">
        <SectionHeader title={t("investmentNeeds.title")} />
        <h2 className="text-3xl uppercase font-bold mb-4 mt-10">
          {t("investmentNeeds.capitalExpenditure")}
        </h2>
        <h3 className="text-2xl uppercase font-bold mb-4">
          {t("investmentNeeds.manufacturingMachinery.title")}
        </h3>
        <ol className="list-disc list-inside mb-16">
          <li>{t("investmentNeeds.manufacturingMachinery.item1")}</li>
          <li>{t("investmentNeeds.manufacturingMachinery.item2")}</li>
          <li>{t("investmentNeeds.manufacturingMachinery.item3")}</li>
          <li>{t("investmentNeeds.manufacturingMachinery.item4")}</li>
        </ol>
        <h3 className="text-2xl uppercase font-bold mb-4">
          {t("investmentNeeds.softwareTools.title")}
        </h3>
        <ol className="list-disc list-inside mb-16">
          <li>{t("investmentNeeds.softwareTools.item1")}</li>
          <li>{t("investmentNeeds.softwareTools.item2")}</li>
          <li>{t("investmentNeeds.softwareTools.item3")}</li>
          <li>{t("investmentNeeds.softwareTools.item4")}</li>
        </ol>
        <h2 className="text-3xl uppercase font-bold mb-4">
          {t("investmentNeeds.personnel.title")}
        </h2>
        <p className="mb-16">{t("investmentNeeds.personnel.text")}</p>
        <h2 className="text-3xl uppercase font-bold mb-4">
          {t("investmentNeeds.benefits.title")}
        </h2>
        <p>{t("investmentNeeds.benefits.text")}</p>
      </div>
    </section>
  );
}
