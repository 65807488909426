export interface SectionHeaderProps {
  title: string;
}

export function SectionHeader(props: SectionHeaderProps) {
  return (
    <h1 className="text-3xl md:text-4xl font-bold text-center uppercase tracking-widest mx-auto">
      {props.title}
    </h1>
  );
}
