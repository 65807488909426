import { useTranslation } from "react-i18next";
import { SectionHeader } from "../components/SectionHeader";

export function GrowthPlan() {
  const { t } = useTranslation();

  return (
    <section className="text-black bg-white">
      <div className="mx-auto py-16 md:py-32 px-10 md:max-w-7xl">
        <SectionHeader title={t("growthPlan.title")} />
        <div className="grid gap-5 md:grid-rows-4 lg:grid-rows-1 lg:grid-cols-4 md:items-top pt-20">
          <div>
            <div className="bg-black text-white p-1 inline font-bold uppercase">
              {t("growthPlan.phase1.name")}
            </div>
            <h2 className="text-base uppercase font-bold my-4">
              {t("growthPlan.phase1.title")}
            </h2>
            <p>{t("growthPlan.phase1.text")}</p>
          </div>
          <div>
            <div className="bg-black text-white p-1 inline font-bold uppercase">
              {t("growthPlan.phase2.name")}
            </div>
            <h2 className="text-base uppercase font-bold my-4">
              {t("growthPlan.phase2.title")}
            </h2>
            <p>{t("growthPlan.phase2.text")}</p>
          </div>
          <div>
            <div className="bg-black text-white p-1 inline font-bold uppercase">
              {t("growthPlan.phase3.name")}
            </div>
            <h2 className="text-base uppercase font-bold my-4">
              {t("growthPlan.phase3.title")}
            </h2>
            <p>{t("growthPlan.phase3.text")}</p>
          </div>
          <div>
            <div className="bg-black text-white p-1 inline font-bold uppercase">
              {t("growthPlan.phase4.name")}
            </div>
            <h2 className="text-base uppercase font-bold my-4">
              {t("growthPlan.phase4.title")}
            </h2>
            <p>{t("growthPlan.phase4.text")}</p>
          </div>
        </div>
      </div>
    </section>
  );
}
